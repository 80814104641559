import { ChangeEvent, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useQueryClient } from '@tanstack/react-query';
import { doUserLogin } from '../helpers/reactQueryUtils';
import { useNavigate } from 'react-router-dom';
import { displayMessage, getParsedId, useUserLocalStorage } from '../helpers/utils';
import { TPerson } from 'TPerson';
import { Loader } from '../components/sharedComponents/SharedComponents';
import { GeneralPageProps } from '../helpers/utilComponents';
import Queue from '../classes/Queue';
import GeneralPurpose from '../classes/GeneralPurpose';
import SettingsArena from '../classes/SettingsArena';


const queuedTables=new Queue();
const generalPurpose=new GeneralPurpose();
const settingsArena = new SettingsArena();
export const Login = (props: any) => {
    const queryClient = useQueryClient();
    const toastRef = useRef(null);
    const navigateFromHere = useNavigate();
    const [logState, setLogState] = useState({
        username: '',
        password: ''
    });
    const [user, updateUser, clearUser] = useUserLocalStorage();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const controlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLogState((prevState) => {
            return { ...prevState, [e.target.id]: e.target.value };
        });
    };
    const performLogin = async (e:any) => {
        e.preventDefault();
        if(logState.username==='' || logState.password===''){
            displayMessage({
                header:'Error',
                message:'Username and or  password is required',
                infoType:'warn',
                toastComponent:toastRef,
                life:3000
            });
            return;
        }
        const appCurrentState=localStorage.getItem('appState');
        try {
            setIsLoading(true);
            appCurrentState===null||appCurrentState==='onLine'?await logRemoteUser():await logLocalUser(logState.username,logState.password);
        } catch (error: any) {
            setIsLoading(false);
        }finally{
            setIsLoading(false);
        }
    };
    const logRemoteUser=async ()=>{
        await queryClient
            .fetchQuery(['userData'], async () => await doUserLogin({ username: logState.username, password: logState.password }), {
                staleTime: 5000, //5 seconds later invalidate this query,
                cacheTime: 5000
            })
            .then(async (data: any) => {
                setIsLoading(false);
                localStorage.setItem('appState','onLine');//because we are loggin in from online;
                await makeUser(data,'onLine');
            })
            .catch((error: any) => {
                displayMessage({
                    header: 'Error',
                    message: error.message,
                    infoType: 'error',
                    toastComponent: toastRef,
                    life: 5000
                });
                setIsLoading(false);
            });
    }
    const logLocalUser=async (username:string,password:string)=>{
        try{
            const logUser=await generalPurpose.verifyUserLogin(username,password);
            if(logUser!==undefined){
                localStorage.setItem('appState','offLine');//because we are logging in from offline;
                await makeUser(logUser,'offLine');
            }else{
                throw new Error('User Login Error. Check password and username!')
            }
        }catch(error:any){
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 3000
            });
        }finally {
            setIsLoading(false);
        }
    }
    const makeUser=async (data:TPerson,appState:string)=>{
        const constructedUser = {
            fullName: data.fullName,
            gender: getParsedId(data.gender),
            outletId: getParsedId(data.outletId!),
            personId: getParsedId(data.personId!),
            storeId: getParsedId(data.storeId!),
            userRole: getParsedId(data.userRole! as number),
            storeDescription:data.storeDescription,
            outletDescription:data.outletDescription,
            storeLogo:data.storeLogo,
            outletPhone:data.outletPhone,
            daysLeftToExpiry:data.daysLeftToExpiry,
            userLocations:typeof data.userLocations ==='string'? JSON.parse(data.userLocations):data.userLocations
        };
        updateUser({ ...data, ...constructedUser } as TPerson);
        appState==='onLine'?settingsArena.saveUserSettings({...await generalPurpose.getUserSettings(),appMode:'onLine'}):
        settingsArena.saveUserSettings({...await generalPurpose.getUserSettings(),appMode:'offLine'});
        setIsLoading(false);
        await queuedTables.initialize();
        navigateFromHere('/choice');
    }
    return (
        <>
            {isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} toastPosition="top-right" />
            <div className="login-body">
                <div className="login-image">
                    <img src={`assets/layout/images/pages/login-${props.colorScheme === 'light' ? 'ondark' : 'onlight'}.png`} alt="easy_sales" />
                </div>
                <div className="login-panel p-fluid">
                    <div className="flex flex-column">
                        <div className="flex align-items-center mb-6 logo-container">
                            <img src={`assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="login-logo" alt="login-logo" />
                            <label className={`text-4xl ml-4 font-bold mt-3 ${props.colorScheme === 'light' ? 'text-black-alpha-50' : 'text-white'}`}>Easy Sales</label>
                            {/*<img src={`assets/layout/images/appname-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="login-appname" alt="login-appname" />*/}
                        </div>
                        <form onSubmit={performLogin}>
                        <div className="form-container">
                            <span className="p-input-icon-left">
                                <i className="pi pi-envelope"></i>
                                <InputText value={logState.username} type="text" onChange={controlChange} placeholder="username" id="username" autoComplete="off"/>
                                {/*<InputText value={logState.username} type="text" onChange={controlChange} placeholder="Email" id="username" autoComplete="off"/>*/}
                            </span>
                            <span className="p-input-icon-left">
                                <i className="pi pi-key"></i>
                                <InputText value={logState.password} onChange={controlChange} type="password" placeholder="Password" id="password"/>
                            </span>
                        </div>

                        <div className="button-container">
                            <Button type="submit" label="Login" onClick={performLogin}></Button>
                            <span>
                                Forgot your password?<button className="p-link">Change here</button>
                            </span>
                            <span>Demo User: angel || Demo Password:12345678</span>
                        </div>
                        </form>
                    </div>

                    <div className="login-footer flex align-items-center">
                        <div className="flex align-items-center login-footer-logo-container">
                            <img src="assets/layout/images/logo-gray.png" className="login-footer-logo" alt="login-footer-logo" />
                            {/*<img src="assets/layout/images/appname-gray.png" className="login-footer-appname" alt="login-footer-appname" />*/}{' '}
                            <label className={`ml-4 font-bold ${props.colorScheme === 'light' ? 'text-black-alpha-50' : 'text-white'} cursor-pointer`}>Easy Sales</label>
                        </div>
                        <span>Copyright 2024</span>
                    </div>
                </div>
            </div>
        </>
    );
};
